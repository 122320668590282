
import { createConversationId, sendMsg } from '@/api/ai/agent'
import { getQrBase64 } from '@/api/template'

export default {
  name : 'AiHome',
  components : {},
  scrollToTop : true,
  data() {
    return {
      messages : [],
      newMessage : '',
      isSending : false
    }
  },
  computed : {},
  created() { },
  mounted() {
    createConversationId().then( res => {
      const conversationId = res.data
      if ( conversationId != null && conversationId.trim() != '' ) {
        localStorage.setItem( 'conversationId', conversationId )
        this.messages.push( { type : 'bot', isImage : false, content : '您好！' } )
      } else {
        this.$message.error( '创建会话失败，请尝试重新进入页面' )
      }
    } )
  },
  methods : {
    sendMessage() {
      if ( !this.newMessage.trim() ) return
      const conversationId = localStorage.getItem( 'conversationId' )
      if ( !conversationId ) {
        this.$message.error( '创建会话失败，请尝试重新进入页面' )
        return
      }
      this.isSending = true
      this.messages.push( { type : 'user', content : this.newMessage } )
      sendMsg( {
        conversationId : conversationId,
        message : this.newMessage
      } ).then( res => {
        this.newMessage = ''
        if ( res.code == 200 ) {
          const isImage = typeof res.data !== 'string' || res.data.startsWith( 'http' )
          if ( isImage ) {
            this.getQrBase64( res.data )
          } else {
            this.messages.push( { type : 'bot', isImage : false, content : res.data } )
          }
        } else {
          this.messages.push( { type : 'bot', content : res.msg } )
        }
        this.isSending = false
      } )
    },
    async getQrBase64( msg ) {
      const p = {
        'detectSpecial' : false, // 是否码点形状作用于探测点
        'detectOutColor' : '', // 非自定义探测点图片情况下,探测点的外边框颜色
        'detectInColor' : '', // 非自定义探测点图片情况下,探测点的内边框颜色
        'drawPreColor' : '0,0,0', // 码点颜色
        'drawPreStyle' : 'RECT', // 码点形状
        'logo' : '', // logo地址
        'logoStyle' : 'NORMAL',
        'padding' : 20, // 边距
        'detectImg' : '', // 探测点图片地址
        'textAdd' : false, // 是否追加文字
        'textContent' : '',
        'textPos' : 'top', // 文字位置
        'textSize' : 16, // 文字大小
        'textColor' : '0,0,0', // 文字颜色
        'bgColor' : `255,255,255`, // 二维码背景
        'bgImg' : '', // 背景图
        'bgOpacity' : `1`, // 背景透明度
        'errorLevel' : 'H', // 容错级别
        'width' : 300, // 宽度
        'height' : 300, // 高度
        'idisContent' : '',
        'msg' : msg
      }

      getQrBase64( p ).then( res => {
        this.messages.push( { type : 'bot', isImage : true, content : `data:image/png;base64,${res.data}` } )
      } )
    }
  }
}

